<template>
  <div :class="{ 'user-message': isUser, 'client-message': !isUser }" class="message">
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  props: {
    message: String,
    isUser: Boolean
  }
};
</script>

<style scoped>
.message {
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  max-width: 60%;
  word-wrap: break-word;
}

.user-message {
  background-color: #09a3eb52;
  color: rgb(5, 5, 5);
  margin-left: auto;
}

.client-message {
  background-color: #e4e6eb;
  color: black;
  margin-right: auto;
}
</style>
