<template>
  <div class="chat-widget" :class="{ minimized: isMinimized }"
    :style="{ top: `${position.y}px`, left: `${position.x}px` }" @mousedown="onMouseDown">
    <div class="header">
      <span class="title">
        <button v-if="view === 'chatView'" @click="goBack">←</button>
        {{ view === 'userList' ? 'User List' : 'Chat with ' + selectedUser.name }}
      </span>
      <div class="controls">
        <button @click="toggleMinimize">
          <i :class="isMinimized ? 'fa-regular fa-window-maximize' : 'fa-solid fa-window-minimize'"></i>
        </button>
      </div>
    </div>

    <div v-if="!isMinimized" class="chat-container">
      <div v-if="view === 'userList'" class="user-list">
        <div v-for="user in users" :key="user.id" class="user-item" @click="selectUser(user)">
          <span>{{ user.name }}</span>
          <span v-if="user.newMessageCount > 0" class="message-count">{{ user.newMessageCount }}</span>
        </div>
      </div>

      <div v-else class="chat-view">
        <div class="messages">
          <MessageComponent v-for="(msg, index) in messages" :key="index" :message="msg.text" :isUser="msg.isUser" />
        </div>
        <div class="input-container">
          <textarea v-model="newMessage" @keyup.enter="sendMessage" type="text" rows="1"
            placeholder="Type a message..." />
          <button @click="sendMessage">Send</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MessageComponent from './MessageComponent.vue';

export default {
  components: { MessageComponent },
  data() {
    return {
      view: 'userList', // 'userList' or 'chatView'
      users: [
        { id: 1, name: 'User 1', newMessageCount: 2 },
        { id: 2, name: 'User 2', newMessageCount: 0 },
        { id: 3, name: 'User 3', newMessageCount: 13 },
      ],
      selectedUser: null,
      messages: [],
      newMessage: '',
      isMinimized: false,
      position: { x: 0, y: 0 },
      isDragging: false,
      dragOffset: { x: 0, y: 0 }
    };
  },
  mounted() {
    this.setPositionToBottomRight();
  },
  methods: {
    selectUser(user) {
      this.selectedUser = user;
      this.view = 'chatView';
      this.messages = [
        { text: 'Hello!', isUser: false },
        { text: 'Hi! How can I help you?', isUser: true },
      ];
      user.newMessageCount = 0; // Reset the message count when the user is selected
    },
    goBack() {
      this.view = 'userList';
      this.selectedUser = null;
    },
    sendMessage() {
      if (this.newMessage.trim() !== '') {
        this.messages.push({ text: this.newMessage, isUser: true });
        this.newMessage = '';
      }
    },
    toggleMinimize() {
      this.isMinimized = !this.isMinimized;
      this.setPositionToBottomRight();
    },
    setPositionToBottomRight() {
      const chatWidth = 300;
      const chatHeight = this.isMinimized ? 60 : 360;
      this.position.x = window.innerWidth - chatWidth - 10;
      this.position.y = window.innerHeight - chatHeight - 10;
    },
    onMouseDown(event) {
      if (event.target.classList.contains('header')) {
        this.isDragging = true;
        this.dragOffset.x = event.clientX - this.position.x;
        this.dragOffset.y = event.clientY - this.position.y;
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseUp);
      }
    },
    onMouseMove(event) {
      if (this.isDragging) {
        this.position.x = event.clientX - this.dragOffset.x;
        this.position.y = event.clientY - this.dragOffset.y;
      }
    },
    onMouseUp() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.onMouseMove);
      document.removeEventListener('mouseup', this.onMouseUp);
    }
  }
};
</script>

<style scoped>
.chat-widget {
  position: fixed;
  max-width: 300px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  cursor: move;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0084ff;
  color: white;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: grab;
}

.title {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.controls {
  display: flex;
  gap: 10px;
}

.controls button {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.controls button:hover {
  opacity: 0.8;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 300px;
}

.user-list {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
}

.user-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.user-item:hover {
  background-color: #f0f0f0;
}

.message-count {
  background-color: #ff0000;
  color: white;
  padding: 2px 5px;
  border-radius: 10px;
  font-size: 12px;
}

.chat-view {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
}

.input-container {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
}

textarea[type="text"] {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  margin-left: 10px;
  padding: 10px;
  background-color: #0084ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #005bb5;
}

.minimized .chat-container {
  display: none;
}

.minimized {
  width: auto;
}
</style>
